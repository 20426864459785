html {
    font-size: 16px;
}
body{
    margin: 0;
    /*font-family: 'Segoe UI', 'Verdana', 'Arial', sans-serif;*/
    font-size: 18px;
    --green-theme-color-standard: #65ad1f;
    --green-theme-color-light: #e6f7d4;
    --green-theme-color-x-light: #f2fbe9;
    --green-theme-color-dark: #33570f;
    --green-theme-color-x-dark: #26410b;
    /*color: var(--green-theme-color-x-light);*/
    --brown-color-standard: #d1771e;
    --orange-color-standard: #ff6f00;

    --serif: 'libre-baskerville', 'Roboto', 'Georgia', 'Microsoft YaHei', serif;
    --sans-serif: 'Segoe UI', 'Verdana', 'Arial', 'Microsoft YaHei', sans-serif;

    --text: #301400;
    --text-light: #6a544e;

}

html,body{
    scroll-behavior: smooth;
}
.sans-serif{
    font-family: 'Segoe UI', 'Verdana', 'Arial', 'Microsoft YaHei', sans-serif;
}
.serif{
    font-family: 'libre-baskerville', 'Roboto', 'Georgia', 'Microsoft YaHei', serif;
}
.green-theme{
    color: var(--green-theme-color-standard);
}
.green-theme-x-light{
    color: var(--green-theme-color-x-light);
}
.green-theme-dark{
    color: var(--green-theme-color-dark);
}

/*@font-face {
  font-family: libre-baskerville;
  src: url('../assets/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: address-sans-pro-cd-black;
  src: url('../assets/fonts/address-sans-pro-cd-black.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: teuton-normal-bold;
  src: url('../assets/fonts/teuton-normal-bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: mundo-sans-std;
  src: url('../assets/fonts/mundo-sans-std.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: mundo-sans-std;
  src: url('../assets/fonts/mundo-sans-std-bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: mundo-sans-std;
  src: url('../assets/fonts/mundo-sans-std-black.otf');
  font-weight: 900;
  font-style: normal;
}*/

/*common page styles*/

/*.header-container {
    background-color: rgba(7, 30, 58, 100);
    background-image: url("../assets/logo/logo.png");
    background-size: cover;
    background-position: left top;
    !*background-color: transparent;*!
    background-blend-mode: difference;
}*/

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding: 150px 60px 130px;
    max-width: 1060px;
    margin: 0 auto;
}

.header-title-1{
    font-size: 1.875em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #75ceee;
    text-align: left;
    margin: 0 0 16px;
}

.header-title-2{
    font-family: "CircularStdBook", Sans-serif;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1.3em;
    color: #ffffff;
}


.content-container {
    /*background-color: white;*/
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    max-width: 768px;
    padding: 6rem 3rem;
    margin: 0 auto;
    line-height: 1.6em;
    color: var(--text);
}

.content a {
    color: var(--green-theme-color-standard);
    text-decoration: none;
    transition: all 0.5s;
}

.content a:hover {
    color: var(--green-theme-color-dark);
    text-decoration: none;
    transition: all 0.5s;
}