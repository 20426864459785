.footer-banner-container {
    margin: 0rem 0 0;
}

.footer-banner {
    display: flex;
    justify-content: space-around;
    align-items: start;
    gap: 3rem;
    color: rgba(255,255,255,.8);
    font-size: 14px;
    line-height: 1.5;
    /*width: 70%;*/
    max-width: 1100px;
    margin: 0 auto 0;
    padding: 3.5rem 5rem;
    /*border-top: 1px solid rgba(0, 0, 0, 0.2);*/
}

@media screen and (max-width: 576px){
    .footer-banner{
        flex-direction: column;
        /*width: 90%;*/
    }
}

.footer-logo-image {
    max-width: 25rem;
}

.footer-column {
    max-width: 15rem;
}

.footer-title {
    color: rgba(255,255,255,1);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 1.2rem;
}

.footer-item {
    margin: 4px 0;
}

.footer-item a {
    color: rgba(255,255,255,.8);
    text-decoration: none;
    transition: all 0.2s;
}

.footer-item a:hover {
    color: rgba(255,255,255,1);
}