.header-container {
    background-color: #cccccc;
    /*background-image: url('../../public/images/banner_background.jpg');*/
    /*background-image: url('../assets/images/hero_background/about_hero_background_original.png');*/
    background-blend-mode: multiply;
    background-size: cover;
    /*background-position: left 0%;*/
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    padding: 35rem 2rem 12rem;
    margin: 0 auto;
}

.header-title-1{
    color: white;
}

.header-title-2{
    color: white;
    text-align: center;
}


.content-container {
    /*background-color: white;*/
}

.content {
    max-width: 1000px;
    padding: 6rem 3rem;
    margin: 0 auto;
    line-height: 1.6em;
    color: var(--text);
}

.content a {
    color: var(--green-theme-color-standard);
    text-decoration: none;
    transition: all 0.5s;
}

.content a:hover {
    color: var(--green-theme-color-dark);
    text-decoration: none;
    transition: all 0.5s;
}